.FooterToolbar {
    min-width: 100%;
    // min-height: 15.5%;
    // max-height: 31%;
    background-color: var(--re-lc-black);
}

.FooterBarRow {
    min-width: 78%;
    vertical-align: middle;
    row-gap: 11.5%;
}

.FooterBarGrid {
    height: 90px;
    text-align: center;
    padding-left: 11%;
    padding-right: 11%;
}

.FooterBarIcon {
    align-self: center;
    font-size: 40px;
    color: var(--re-lc-white-shade);
}

.FooterBarColumn {
    padding-left: 2.5%;
}