@import '../theme/variables.scss';

.ComponentModal {
    top: 62px;
    bottom: 90px;
    height: auto;
    width: 100%;
    //    background-color: aqua;


    // NOTE For locating it in the background
    .modal-wrapper {
        background-color: transparent !important;
        width: auto;
        top: 9px;
        bottom: 10px;
        right: 19px;
        left: 19px;
        height: auto;
        border-radius: 27px;
        position: absolute;
        margin: 0px;
        padding: 0px;

        // @media only screen and (max-height: 800px) {
        //     height: 96%;
        // }

        // @media only screen and (max-height: 600px) {
        //     height: 92%;
        // }

        box-shadow: none;
    }
}