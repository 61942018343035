@import '../theme/variables.scss';

.SettingsToolBar {
    background: $settings;
    color: $white;
}

.XButton {
    font-size: 30px;
}

.ToggleLabel {
    font-size: x-large;
    text-align: left;
    color: $dark-text;
}

.ToggleButton {
    left: 0px;
    width: 50px;
    height: 20px;
    --handle-border-radius: 15px;
    --handle-background-checked: #9fb0db;
    --background-checked: #455b92;
}

.ClimateSymbolSegment {
    text-align: end;
    width: 20%;
    height: 100%;
    --background: var(--ion-color-light);
    right: 0px;
}

.ClimateSymbolSegmentButton {

    font-size: large;
    --color: var(--ion-color-medium);
    --background-checked: #455b92;
    --color-checked: #b5c2e4;
    --background-hover: var(--ion-color-medium);
    --color-hover: var(--ion-color-tertiary);
    --background-hover-opacity: 15%;
    --indicator-color: #b5c2e4;
}

.PageTitle {
    font-size: xx-large;
    color: $white;
    text-align: center;
    padding-top: 18%;
}

.ToggleItem {
    --min-height: 100%;
}