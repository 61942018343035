@import '../theme/variables.scss';

ion-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-radius: 27.8px;
    top: 0%;
    padding: 0;
    margin: 0px;
    width: 100%;
    --background: var(--re-lc-dark-grey);
    // NOTE Controls the background of the climate cards
    box-shadow: none;
}

.climateRow {
    display: flex;
    flex-flow: row;
    gap: 16px;
    justify-content: space-between;
    margin: 0px;
    padding: 0px;
    height: 100%;
    width: 100%;
    //  background-color: greenyellow;
    align-items: stretch;
}

.climateColumn {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    margin: 0px;
    padding: 0px;
    height: 100%;
}

.High {
    width: 100%;
    height: fit-content;
    font-family: 'Poppins', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 19.2px;
    text-align: center;
    align-self: center;
}

.OFF {
    width: 100%;
    height: fit-content;
    font-family: 'Poppins', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 19.2px;
    text-align: center;
    align-self: center;
}

.Fan-Intensity-Range {
    transform: rotate(-90deg);
    -webkit-appearance: none;
    border-radius: 15px;
    height: 17px;
    width: 300px;
    margin-top: 50%;
    margin-bottom: 50%;
    // background-image: url('../assets/climate_slider_background.jpg');
    background-image: url('../assets/slider-background.svg');
    background-size: 300px;
}

.Fan-Intensity-Range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 200px;
    background: white;
    border-radius: 14px;
    z-index: 12;
}

.Temperature-Button {
    font-size: 64px;
    width: 100%;
    height: 100%;
    margin: 0;
}

.Temperature-Button-Icon {
    color: $medium-light-text;
}

.Temperature-Display {
    font-size: 50px;
    color: var(--re-lc-white);
    align-self: flex-end;
    margin-right: 60px;
    height: 60px;
    margin-top: -30px;
    margin-bottom: -30px;
}

.Thermometer-Icon {
    transform: translateY(-5%);
    color: $medium-text;
    font-size: 100px;
    align-self: flex-start;
    margin-left: 50px;
    margin-top: -25px;
    margin-bottom: -25px;
}





.Fan {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    border-radius: 27px;
    row-gap: 0px;
    align-self: stretch;
    height: auto;
    justify-items: space-around;
    margin: 10px 0px;

    // margin: 16px;


    .Direction-Icon {
        font-size: 8vh;
        text-align: center;
        //   width: 100%;
        //  width: 12vw;
        left: 0;
        right: 0;
        padding-block: 24px;
        margin: 0%;
    }

    .Segment {
        display: flex;
        flex-flow: column wrap;
        //row-gap: 100px;
        align-self: stretch;
        align-items: center;
        align-content: stretch;
        margin: 0%;
        //   width: inherit;
    }

    .Button {
        display: flex;
        align-self: center;
        flex-flow: column wrap;
        align-content: stretch;
        justify-content: stretch;
        //   margin-top: 14px;
        justify-self: space-around;
        margin: 1%;
        width: 90%;
        padding: 0%;
        border-radius: 27px;
        --border-radius: 27px;
        //   padding-inline: 5%;
        --color: var(--ion-color-tertiary);
        --color-checked: #1c1d23;
        --background-checked: white;
        --background: transparent;
        --indicator-color:
            transparent;
        --ripple-color: transparent;
    }
}