@import '../theme/variables.scss';

.TopToolbar {
    min-width: 100%;
    height: 64px;
    padding: 0.5% 3%;

}

.TopBarRow {
    height: 5%;
    vertical-align: middle;
    justify-content: space-evenly;
    align-items: center;
}

.Time {
    width: 6%;
    height: 6%;
    font-family: 'Poppins';
    font-size: 14.7px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}



.BluetoothRow {
    padding-left: 92%;
}

.BatteryIcon {
    transform: scale(200%);
    transform: rotate(-90deg);
}

.BatteryText {
    height: 3.2%;
    transform: translateX(3%);
    font-family: 'Poppins';
    font-size: 14.4px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    vertical-align: middle;
}

.BluetoothIcon {
    transform: scale(125%);
}