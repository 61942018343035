/**
    colors.scss

    @author - Ratik Kapoor
    @author - Brooke Kindleman
    @description - Holds colours to use across app for consistency
*/

// Used to allow use access to the "Poppins" font, used throughout.
// Set font-family to 'Poppins' to use for any text component
//@use '../assets/fonts/PoppinsFont/Poppins-Regular.ttf';
@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/PoppinsFont/Poppins-Regular.ttf');
}

// For use in custom .CSS formatting some react components require such as '--background'
// All colours are named based on the light mode colour scheme
// 'var' is used to ensure proper transition between light/dark mode so all elements of the
//      UI are visible, regardless of the active colour theme.

$light-text: var(--ion-color-light);
$medium-text: var(--ion-color-medium);
$dark-text: var(--ion-color-dark);
$medium-light-text: var(--ion-color-secondary);
$medium-dark-text: var(--ion-color-tertiary);

$modal-background: var(--ion-color-secondary);
$modal-light-inset: var(--ion-color-secondary);

$white: #ededed;
$navigation: #608091;
$car: #65918a;
$climate: #cf9856;
$music: #835b8f;
$settings: #4d5d84;

:root {

    --re-lc-white: #ffffff;
    --re-lc-white-rgb: rgb(255, 255, 255);
    --re-lc-white-contrast: #000000;
    --re-lc-white-contrast-rgb: rgb(0, 0, 0);
    --re-lc-white-shade: #ebebeb;
    --re-lc-white-tint: #ffffff;

    --re-lc-indigo: #060621;
    --re-lc-indigo-rgb: rgb(6, 6, 33);
    --re-lc-indigo-contrast: #ffffff;
    --re-lc-indigo-contrast-rgb: rgb(255, 255, 255);
    --re-lc-indigo-shade: #040411;
    --re-lc-indigo-tint: #21214b;

    --re-lc-dark-grey: #1c1d23;
    --re-lc-dark-grey: rgb(28, 29, 35);
    --re-lc-dark-grey-contrast: #ffffff;
    --re-lc-dark-grey-contrast-rgb: rgb(255, 255, 255);
    --re-lc-dark-grey-shade: #0f1013;
    --re-lc-dark-grey-tint: #3d3e44;

    --re-lc-black: #000000;
    --re-lc-black: rgb(0, 0, 0);
    --re-lc-black-contrast: #ffffff;
    --re-lc-black-contrast-rgb: rgb(255, 255, 255);
    --re-lc-black-shade: #000000;
    --re-lc-black-tint: #1a1a1a;

    --re-lc-light-grey: #696969;
    --re-lc-light-grey: rgb(105, 105, 105);
    --re-lc-black-contrast: #ffffff;
    --re-lc-black-contrast-rgb: rgb(255, 255, 255);
    --re-lc-black-shade: #535353;
    --re-lc-black-tint: #808080;

    --re-lc-horse-grey: #2e3039;
    --re-lc-horse-grey: rgb(46, 48, 57);
    --re-lc-black-contrast: #ffffff;
    --re-lc-black-contrast-rgb: rgb(255, 255, 255);
    --re-lc-black-shade: #28292b;
    --re-lc-black-tint: #43444b;



    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56,
        128,
        255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,
        255,
        255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45,
        211,
        111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,
        255,
        255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,
        196,
        9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,
        0,
        0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235,
        68,
        90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,
        255,
        255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #23272f;
    --ion-color-dark-rgb: 35,
        39,
        47;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,
        255,
        255;
    --ion-color-dark-shade: #1f2229;
    --ion-color-dark-tint: #393d44;

    --ion-color-tertiary: #40474f;
    --ion-color-tertiary-rgb: 64,
        71,
        79;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,
        255,
        255;
    --ion-color-tertiary-shade: #383e46;
    --ion-color-tertiary-tint: #535961;

    --ion-color-medium: #69717b;
    --ion-color-medium-rgb: 105,
        113,
        123;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255,
        255,
        255;
    --ion-color-medium-shade: #5c636c;
    --ion-color-medium-tint: #787f88;

    --ion-color-secondary: #bdc0c7;
    --ion-color-secondary-rgb: 189,
        192,
        199;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0,
        0,
        0;
    --ion-color-secondary-shade: #a6a9af;
    --ion-color-secondary-tint: #c4c6cd;

    --ion-color-light: #dee1e4;
    --ion-color-light-rgb: 222,
        225,
        228;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,
        0,
        0;
    --ion-color-light-shade: #c3c6c9;
    --ion-color-light-tint: #e1e4e7;

    // Relectric
    .ion-color-relectric-light {
        --ion-color-base: #d3d3d3;
    }

    .ion-color-relectric-navigation {
        --ion-color-base: #608091;
    }

    .ion-color-relectric-car {
        --ion-color-base: #65918a;
    }

    .ion-color-relectric-climate {
        --ion-color-base: #cf9856;
    }

    .ion-color-relectric-music {
        --ion-color-base: #835b8f;
    }

    .ion-color-relectric-settings {
        --ion-color-base: #4d5d84;
    }

    .ion-color-white {
        --ion-color-base: #ededed;
    }
}

@media (prefers-color-scheme: light) {
    body.light {
        --ion-color-primary: #3880ff;
        --ion-color-primary-rgb: 56, 128, 255;
        --ion-color-primary-contrast: #ffffff;
        --ion-color-primary-contrast-rgb: 255, 255, 255;
        --ion-color-primary-shade: #3171e0;
        --ion-color-primary-tint: #4c8dff;

        --ion-color-success: #2dd36f;
        --ion-color-success-rgb: 45, 211, 111;
        --ion-color-success-contrast: #ffffff;
        --ion-color-success-contrast-rgb: 255, 255, 255;
        --ion-color-success-shade: #28ba62;
        --ion-color-success-tint: #42d77d;

        --ion-color-warning: #ffc409;
        --ion-color-warning-rgb: 255, 196, 9;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0ac08;
        --ion-color-warning-tint: #ffca22;

        --ion-color-danger: #eb445a;
        --ion-color-danger-rgb: 235, 68, 90;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #cf3c4f;
        --ion-color-danger-tint: #ed576b;

        --ion-color-dark: #23272f;
        --ion-color-dark-rgb: 35, 39, 47;
        --ion-color-dark-contrast: #ffffff;
        --ion-color-dark-contrast-rgb: 255, 255, 255;
        --ion-color-dark-shade: #1f2229;
        --ion-color-dark-tint: #393d44;

        --ion-color-tertiary: #40474f;
        --ion-color-tertiary-rgb: 64, 71, 79;
        --ion-color-tertiary-contrast: #ffffff;
        --ion-color-tertiary-contrast-rgb: 255, 255, 255;
        --ion-color-tertiary-shade: #383e46;
        --ion-color-tertiary-tint: #535961;

        --ion-color-medium: #69717b;
        --ion-color-medium-rgb: 105, 113, 123;
        --ion-color-medium-contrast: #ffffff;
        --ion-color-medium-contrast-rgb: 255, 255, 255;
        --ion-color-medium-shade: #5c636c;
        --ion-color-medium-tint: #787f88;

        --ion-color-secondary: #bdc0c7;
        --ion-color-secondary-rgb: 189, 192, 199;
        --ion-color-secondary-contrast: #000000;
        --ion-color-secondary-contrast-rgb: 0, 0, 0;
        --ion-color-secondary-shade: #a6a9af;
        --ion-color-secondary-tint: #c4c6cd;

        --ion-color-light: #dee1e4;
        --ion-color-light-rgb: 222, 225, 228;
        --ion-color-light-contrast: #000000;
        --ion-color-light-contrast-rgb: 0, 0, 0;
        --ion-color-light-shade: #c3c6c9;
        --ion-color-light-tint: #e1e4e7;
    }

    .md {
        body.light {
            --ion-background-color: #fbfbfb;
            --ion-background-color-rgb: 251, 251, 251;

            --ion-text-color: #121317;
            --ion-text-color-rgb: 18, 19, 23;

            --ion-color-step-50: #efeff0;
            --ion-color-step-100: #e4e4e4;
            --ion-color-step-150: #d8d8d9;
            --ion-color-step-200: #cccdcd;
            --ion-color-step-250: #c1c1c2;
            --ion-color-step-300: #b5b5b7;
            --ion-color-step-350: #a9aaab;
            --ion-color-step-400: #9e9ea0;
            --ion-color-step-450: #929394;
            --ion-color-step-500: #878789;
            --ion-color-step-550: #7b7b7e;
            --ion-color-step-600: #6f7072;
            --ion-color-step-650: #646467;
            --ion-color-step-700: #58595b;
            --ion-color-step-750: #4c4d50;
            --ion-color-step-800: #414145;
            --ion-color-step-850: #353639;
            --ion-color-step-900: #292a2e;
            --ion-color-step-950: #1e1f22;
        }
    }
}

@media (prefers-color-scheme: dark) {
    body.dark {
        --ion-color-primary: #3880ff;
        --ion-color-primary-rgb: 56, 128, 255;
        --ion-color-primary-contrast: #ffffff;
        --ion-color-primary-contrast-rgb: 255, 255, 255;
        --ion-color-primary-shade: #3171e0;
        --ion-color-primary-tint: #4c8dff;

        --ion-color-secondary: #40474f;
        --ion-color-secondary-rgb: 64, 71, 79;
        --ion-color-secondary-contrast: #ffffff;
        --ion-color-secondary-contrast-rgb: 255, 255, 255;
        --ion-color-secondary-shade: #383e46;
        --ion-color-secondary-tint: #535961;

        --ion-color-tertiary: #bdc0c7;
        --ion-color-tertiary-rgb: 189, 192, 199;
        --ion-color-tertiary-contrast: #000000;
        --ion-color-tertiary-contrast-rgb: 0, 0, 0;
        --ion-color-tertiary-shade: #a6a9af;
        --ion-color-tertiary-tint: #c4c6cd;

        --ion-color-success: #2dd36f;
        --ion-color-success-rgb: 45, 211, 111;
        --ion-color-success-contrast: #ffffff;
        --ion-color-success-contrast-rgb: 255, 255, 255;
        --ion-color-success-shade: #28ba62;
        --ion-color-success-tint: #42d77d;

        --ion-color-warning: #ffc409;
        --ion-color-warning-rgb: 255, 196, 9;
        --ion-color-warning-contrast: #000000;
        --ion-color-warning-contrast-rgb: 0, 0, 0;
        --ion-color-warning-shade: #e0ac08;
        --ion-color-warning-tint: #ffca22;

        --ion-color-danger: #eb445a;
        --ion-color-danger-rgb: 235, 68, 90;
        --ion-color-danger-contrast: #ffffff;
        --ion-color-danger-contrast-rgb: 255, 255, 255;
        --ion-color-danger-shade: #cf3c4f;
        --ion-color-danger-tint: #ed576b;

        --ion-color-dark: #dee1e4;
        --ion-color-dark-rgb: 222, 225, 228;
        --ion-color-dark-contrast: #000000;
        --ion-color-dark-contrast-rgb: 0, 0, 0;
        --ion-color-dark-shade: #c3c6c9;
        --ion-color-dark-tint: #e1e4e7;

        --ion-color-medium: #69717b;
        --ion-color-medium-rgb: 105, 113, 123;
        --ion-color-medium-contrast: #ffffff;
        --ion-color-medium-contrast-rgb: 255, 255, 255;
        --ion-color-medium-shade: #5c636c;
        --ion-color-medium-tint: #787f88;

        --ion-color-light: #23272f;
        --ion-color-light-rgb: 35, 39, 47;
        --ion-color-light-contrast: #ffffff;
        --ion-color-light-contrast-rgb: 255, 255, 255;
        --ion-color-light-shade: #1f2229;
        --ion-color-light-tint: #393d44;
    }

    .md {
        body.dark {
            --ion-background-color: var(--re-lc-black);
            --ion-background-color-rgb: 18, 19, 23;

            --ion-text-color: var(--re-lc-white-shade);
            --ion-text-color-rgb: 246, 246, 246;

            --ion-color-step-50: #1d1e22;
            --ion-color-step-100: #292a2d;
            --ion-color-step-150: #343538;
            --ion-color-step-200: #404044;
            --ion-color-step-250: #4b4c4f;
            --ion-color-step-300: #56575a;
            --ion-color-step-350: #626265;
            --ion-color-step-400: #6d6e70;
            --ion-color-step-450: #79797b;
            --ion-color-step-500: #848587;
            --ion-color-step-550: #8f9092;
            --ion-color-step-600: #9b9b9d;
            --ion-color-step-650: #a6a7a8;
            --ion-color-step-700: #b2b2b3;
            --ion-color-step-750: #bdbdbe;
            --ion-color-step-800: #c8c9c9;
            --ion-color-step-850: #d4d4d5;
            --ion-color-step-900: #dfdfe0;
            --ion-color-step-950: #ebebeb;
        }
    }
}

// Used for manually turnign on light mode
body.light {
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #23272f;
    --ion-color-dark-rgb: 35, 39, 47;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1f2229;
    --ion-color-dark-tint: #393d44;

    --ion-color-tertiary: #40474f;
    --ion-color-tertiary-rgb: 64, 71, 79;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #383e46;
    --ion-color-tertiary-tint: #535961;

    --ion-color-medium: #69717b;
    --ion-color-medium-rgb: 105, 113, 123;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #5c636c;
    --ion-color-medium-tint: #787f88;

    --ion-color-secondary: #bdc0c7;
    --ion-color-secondary-rgb: 189, 192, 199;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #a6a9af;
    --ion-color-secondary-tint: #c4c6cd;

    --ion-color-light: #dee1e4;
    --ion-color-light-rgb: 222, 225, 228;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #c3c6c9;
    --ion-color-light-tint: #e1e4e7;
}

.md {
    body.light {
        --ion-background-color: #fbfbfb;
        --ion-background-color-rgb: 251, 251, 251;

        --ion-text-color: #121317;
        --ion-text-color-rgb: 18, 19, 23;

        --ion-color-step-50: #efeff0;
        --ion-color-step-100: #e4e4e4;
        --ion-color-step-150: #d8d8d9;
        --ion-color-step-200: #cccdcd;
        --ion-color-step-250: #c1c1c2;
        --ion-color-step-300: #b5b5b7;
        --ion-color-step-350: #a9aaab;
        --ion-color-step-400: #9e9ea0;
        --ion-color-step-450: #929394;
        --ion-color-step-500: #878789;
        --ion-color-step-550: #7b7b7e;
        --ion-color-step-600: #6f7072;
        --ion-color-step-650: #646467;
        --ion-color-step-700: #58595b;
        --ion-color-step-750: #4c4d50;
        --ion-color-step-800: #414145;
        --ion-color-step-850: #353639;
        --ion-color-step-900: #292a2e;
        --ion-color-step-950: #1e1f22;
    }
}

// Used for manually turning on dark mode.
body.dark {
    --ion-color-primary: #3880ff;
    --ion-color-primary-rgb: 56, 128, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3171e0;
    --ion-color-primary-tint: #4c8dff;

    --ion-color-secondary: #40474f;
    --ion-color-secondary-rgb: 64, 71, 79;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #383e46;
    --ion-color-secondary-tint: #535961;

    --ion-color-tertiary: #bdc0c7;
    --ion-color-tertiary-rgb: 189, 192, 199;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #a6a9af;
    --ion-color-tertiary-tint: #c4c6cd;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-dark: #dee1e4;
    --ion-color-dark-rgb: 222, 225, 228;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #c3c6c9;
    --ion-color-dark-tint: #e1e4e7;

    --ion-color-medium: #69717b;
    --ion-color-medium-rgb: 105, 113, 123;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #5c636c;
    --ion-color-medium-tint: #787f88;

    --ion-color-light: #23272f;
    --ion-color-light-rgb: 35, 39, 47;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1f2229;
    --ion-color-light-tint: #393d44;
}

.md {
    body.dark {
        --ion-background-color: var(--re-lc-black);
        --ion-background-color-rgb: 18, 19, 23;

        --ion-text-color: #f6f6f6;
        --ion-text-color-rgb: 246, 246, 246;

        --ion-color-step-50: #1d1e22;
        --ion-color-step-100: #292a2d;
        --ion-color-step-150: #343538;
        --ion-color-step-200: #404044;
        --ion-color-step-250: #4b4c4f;
        --ion-color-step-300: #56575a;
        --ion-color-step-350: #626265;
        --ion-color-step-400: #6d6e70;
        --ion-color-step-450: #79797b;
        --ion-color-step-500: #848587;
        --ion-color-step-550: #8f9092;
        --ion-color-step-600: #9b9b9d;
        --ion-color-step-650: #a6a7a8;
        --ion-color-step-700: #b2b2b3;
        --ion-color-step-750: #bdbdbe;
        --ion-color-step-800: #c8c9c9;
        --ion-color-step-850: #d4d4d5;
        --ion-color-step-900: #dfdfe0;
        --ion-color-step-950: #ebebeb;
    }
}