@import '../theme/variables.scss';

.CarToolBar {
    background: $car;
    color: $white;
}

.AerialView {
    pointer-events: none;
    height: 80%;
    width: 100%;
    text-align: center;
    padding: 5% 0 0 0;
    z-index: 0;
}

.ImagePreview {
    pointer-events: none;

}

.BatteryStats {
    float: right;

    .BatteryIcon {
        font-size: 5rem;
        color: #008080;
    }

    .BatteryPercentage {
        font-size: 3rem;
        color: #008080;
    }
}

.blur {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
}

.CenterStats {
    text-align: center;
}

.AerialViewPassengerDoor {
    font-size: 112px;
    position: absolute;
    left: 206px;
    top: 91px;
    z-index: 2;
}

.AerialViewDriverDoor {
    font-size: 112px;
    position: absolute;
    left: 88px;
    top: 91px;
    z-index: 3;

}

.RightStats {
    height: auto;
}

.LeftStats {
    height: auto;
}